import { get, put,Delete,post } from '../axios'

// 推广海报-列表 
export const posterList = params => get(`/user/web/market/promote/poster/list`,params);
// 推广海报-新增
export const posterAdd = params => post(`/user/web/market/promote/poster/add`,params);

// 推广海报-删除
export const posterDel = params => Delete(`/user/web/market/promote/poster/delete/${params}`,params);

// 推广海报-详情
export const posterView = params => get(`/user/web/market/promote/poster/view/${params}`,params);

// 门店端-活动管理-修改
export const posterUp = params => put(`/user/web/market/promote/poster/update`,params);

// 推广海报-状态开启
export const posterStateUp = params =>get(`/user/web/market/promote/poster/state/update/${params}`,params);




