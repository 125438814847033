<template>
  <div class="divBox">
    <div class="posterBox" ref="poster">
      <img class="bjImg" :src="details.image" />
      <div class="bottomBox">
        <div class="text">
          <div class="text1">{{ details.title }}</div>
          <div class="text2">{{ details.posterDesc }}</div>
        </div>
        <img class="ewm" :src="qrcodeImg" />
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="generatePoster">下载图片</el-button>
      <el-button @click="close" style="margin-right: 20px"
        >取 消</el-button
      >
    </div>
  </div>
</template>
<script>
import { posterView } from "@/api/market/poster";
import { getStoreQrcode } from "@/api/data/data";
import html2canvas from "html2canvas";
export default {
  name: "createPoster",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      details: {
        image:''
      },
      qrcodeImg: "",
    };
  },
  created() {
    if (this.id) {
      this.getView();
      this.getQrcode();
    }
  },
  methods: {
    //获取门店二维码
    getQrcode() {
      getStoreQrcode().then((res) => {
        this.downloadIamge(res.data,1)
      });
    },
    getView() {
      posterView(this.id)
        .then((res) => {
          this.details = res.data;
          this.downloadIamge(this.details.image,2)
        })
        .catch(({ msg }) => {});
    },
    generatePoster() {
      const element = this.$refs.poster;

      html2canvas(element).then((canvas) => {
        // 创建一个图像元素
        let img = new Image();
        img.src = canvas.toDataURL("image/png");
        img.style.width = "100%";
        img.style.height = "auto";

        // 添加到body中进行显示
        document.body.appendChild(img);

        // 可以添加下载代码，将canvas转换为图片并下载
        let link = document.createElement("a");
        link.download = "poster.png";
        link.href = canvas.toDataURL("image/png");
        link.click();
      });
    },
    downloadIamge(imgsrc,type) {
      let that = this
      //下载图片地址和图片名
      let image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        if(type==1){
          console.log(1)
          that.qrcodeImg = url
        }
        if(type==2){
          console.log(1231231222222)
          that.details.image = url
        }

      };
      image.src = imgsrc;
    },
    close(){
      this.$emit("close")
    }
  },
};
</script>
  
<style lang="scss" scoped>
.posterBox {
  width: 345px;
  height: 460px;
  position: relative;
  margin: 0 auto;
  border-radius: 10px;
}
.bjImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.bottomBox {
  position: absolute;
  width: 315px;
  padding: 0 20px;
  box-sizing: border-box;
  height: 120px;
  bottom: 15px;
  left: 15px;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ewm {
  width: 80px;
  height: 80px;
}
.text {
  width: 180px;
}
.text1 {
  font-size: 14px;
  color: #2c3041;
}
.text2 {
  font-size: 12px;
  color: #a0a5b5;
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.dialog-footer {
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
}
</style>
  