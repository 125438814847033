<template>
  <div class="divBox">
    <el-card class="mt14">
      <el-button type="primary" @click="add" size="mini">新增</el-button>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        highlight-current-row
      >
       <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号"
        />
        <el-table-column prop="sort" label="图片" min-width="120">
          <template slot-scope="scope">
            <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.image"
                :preview-src-list="[scope.row.image]"
              />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" min-width="120" />
        <el-table-column prop="posterDesc" label="描述" min-width="120" />
        <el-table-column label="状态" min-width="120">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              :active-value="1"
              :inactive-value="2"
              active-text="开启"
              inactive-text="关闭"
              @change="onchangeIsShow(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="120" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="onEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="el-icon-delete"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="el-icon-view"
              @click="viewPoster(scope.row.id)"
              >查看海报</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="标题:" prop="title">
          <el-input
            class="inputWidth"
            placeholder="请输入标题"
            maxlength="10"
            v-model="form.title"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述:" prop="posterDesc">
          <el-input
            class="inputWidth"
            placeholder="请输入描述"
            maxlength="100"
            v-model="form.posterDesc"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动列表图:" prop="image">
          <FormImgUpload
            :url="form.image"
            @upload="upload"
            :limit="1"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
          <div class="tipImg">尺寸推荐:670X304，格式：jpeg,png,jpg</div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="vLoading"  @click="submitForm('form')">提交</el-button>
          <!-- <el-button @click="resetForm('form')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="海报"
      :visible.sync="posterVisible"
      width="700px"
    >
   <CreatePoster @close="posterVisible=false" v-if="posterVisible" :id="checkId" />
  </el-dialog>
  </div>
</template>
<script>
import {
  posterList,
  posterAdd,
  posterDel,
  posterView,
  posterUp,
  posterStateUp
} from "@/api/market/poster";
import { getIndex } from "@/utils/other";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import CreatePoster from "./createPoster.vue";
export default {
  name: "poster",
  components: {
    FormImgUpload,
    CreatePoster
  },
  data() {
    return {
      posterVisible:false,
      vLoading:false,
      title: "新增活动",
      checkId:"",
      tableFrom: { 
        pageNum: 1,
        pageSize: 20,
        status: "1",
        storeId: "",
      },
      storeList: {},
      form: {
        name: "",
        posterDesc:"",
        image:[],
      },
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      dialogVisible: false,
      dialogVisibleLink: false,
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        posterDesc: [{ required: true, message: "请输入描述", trigger: "blur" }],
        image: [{ required: true, message: "请上传图片", trigger: "blur" }]
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
    uploadDelete() {},
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadAfter(res, done) {
      done();
    },
    upload(e) {
      console.log("123", e);
      if (Array.isArray(e)) {
        this.form.image = [...e];
        this.$forceUpdate();
      } else {
        this.form.image = [...this.form.image, e];
        this.$forceUpdate();
      }
    },
    editoChange(e) {
      this.form.content = e;
    },
    //获取门店接口

    getList() {
      this.listLoading = true;
      posterList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },

    // 删除
    handleDelete(id) {
      let that = this;
      this.$confirm(`删除这条数据`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            posterDel(id)
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code === 200) {
                  that.getList();
                  that.$message.success(res.msg);
                } else {
                  that.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        },
      });
    },

    onchangeIsShow(row) {
      posterStateUp(row.id)
        .then(({ msg }) => {
          this.getList();
          this.$message.success(msg);
        })
        .catch(({ msg }) => {
          this.$message.error(msg);
          this.getList();
        });
    },
    pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    storeChange() {
      this.form.aboutId = ""; 
      this.form.aboutName = "";
    },
    getGoodsItem(row) {
      this.form.aboutId = row.id;
      this.form.aboutName = row.name;
      this.dialogVisibleLink = false;
    },
    formSubmit() {},
    add() {
      this.title = "新增海报";
      this.dialogVisible = true;
    },
    // 编辑
    onEdit(row) {
      this.title = "编辑海报";
      posterView(row.id)
        .then((res) => {
          this.form = JSON.parse(JSON.stringify(res.data));
          this.form.image = [this.form.image]
          this.dialogVisible = true;
        })
        .catch(({ msg }) => {});
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    handleCloseLink() {
      this.dialogVisibleLink = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            this.vLoading = true
            posterUp({...this.form,image:this.form.image[0]}).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              this.vLoading = false
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.getList();
                this.handleClose();
                // done(); 
              } else {
                this.$message.error(res.msg);
                // done();
              }
            }).catch((err)=>{
              this.vLoading = false
            });
          } else {
            console.log('123',this.form)
            this.vLoading = true
            posterAdd({...this.form,image:this.form.image[0]}).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              this.vLoading = false
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.getList();
                this.handleClose();
                // done();
              } else {
                this.$message.error(res.msg);
                // done();
              }
            }).catch((err)=>{
              this.vLoading = false
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    viewPoster(id){
      console.log('123',id)
      this.checkId = id
      this.posterVisible = true
    }
  },
};
</script>
  
<style lang="scss" scoped>
.changeLink {
  width: 200px;
  height: 30px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  font-size: 14px;
  padding-left: 14px;
  box-sizing: border-box;
  cursor: pointer;
}
.inputWidth {
  width: 300px;
}
.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
  